import React from "react";
import logo from "./Shoeworld_Logo.png";
import "./Footer.css";
import ig from './insta.png'
import fb from './fb.png'

const Footer = () => {
  return (
    <div className="footer" id="footer">
      <div className="footer-content">
        <div className="footer-content-left">
          <h1 >Visit Us</h1>
          <h2>
            <img src={logo} alt="logo" />
          </h2>
          <p >
            Explore our wide range of footwear at any of our three branches
            across Raichur district. Whether you are looking for the latest
            trends or timeless classics, Shoe World has something for everyone.
          </p>
          <div className="footer-social-icons"></div>
        </div>
        <div className="footer-content-center">
          <h2 style={{ color: "black" }}>BRANCHES</h2>
          <ul>
            <li>📍Manvi</li>
            <li>📍Sindhanur</li>
            <li>📍Lingasugur</li>
          </ul>
        </div>
        <div className="footer-content-right">
          <h2 style={{ color: "black" }}>Contact Us</h2>
          <p >Have questions or need assistance?
          Feel free to reach out to us</p>
          <ul>
            <li >1. 9738849785<br/> 2. 7847036123<br/> 3. 9742501091</li>
            <li><img className="ig" src={ig} alt="ig"/><a style={{color:'blue'}} className="ig" href="https://www.instagram.com/shoeworld_ka36/"> shoeworldka_36</a></li>
            <li><img className="fb" src={fb} alt="fb"/><a style={{color:'blue'}} className="fb" href="https://www.facebook.com/shoeworldmanvi?mibextid=qi2Omg&rdid=TmiWlaMvYk9h6e2v&share_url=https%3A%2F%2Fwww.facebook.com%2Fshare%2FkFXTXZ9hcTLJb1sh%2F%3Fmibextid%3Dqi2Omg">ShoeWorld</a></li>
          </ul>
          <div className="app-download-platforms"></div>
        </div>
      </div>
      <hr />
      <p className="footer-copyright">
        Copyright 2024 &copy; MOHAMMED AAMIR SUHAIL SIDDIQUI -All Rights Reserved
      </p>
    </div>
  );
};

export default Footer;
