import React from "react";
import Slider from "../../components/Slider/Slider";
import Brand from "../../components/Brand/Brand";
import "./Home.css";



function Home() {
  return (
    <>
    <div >
      <Slider  />
      <Brand />
      </div>
      
      <div className="about" id="about">
        <h1>ABOUT US</h1>
      </div>
      <h1 className="front">Welcome to Shoe World </h1>
      <h3 className="since">Since 1984</h3>
      <br />
      <div className="desc">
        <p>
          
          Welcome to Shoe World, your ultimate destination for quality footwear.
          Established in 1984 by Dawood Siddiqui, Shoe World has been synonymous
          with trust and excellence for over three decades. We take pride in
          offering a wide range of shoes that combine style, comfort, and
          durability.
        </p>
      </div>
      <div className="legacy">
        <div className="legacy-head">
        <h1>Our Legacy</h1>
        </div>
        <br />
        <p>
          With a rich heritage spanning over 40 years, Shoe World has grown from
          a single store to three thriving branches across Raichur district.The journey began
          with our founder Dawood Siddiqui, Introducing the first store Lord's Footwear in 1984.
          Today, the business is managed by the second generation: <b>Aamir Sohail
          Siddiqui & Aaqhil Zeeshan Siddiqui</b>. Their commitment to maintaining
          the high standards set by their predecessor ensures that Shoe World
          continues to be a beloved name in the community.
        </p>
      </div>
      <div className="products">
        <h1>Our Products</h1>
        <br />
        <p>
          At Shoe World, we believe that shoes are more than just
          accessories-they are a reflection of your personality and style. Our
          extensive collection includes
          <br /> • <span>Men's Footwear</span>: From formal shoes to casual sneakers, find
          the perfect pair for any occasion.
          <br /> • <span>Women's Footwear</span>: Elegant heels, comfortable flats, and
          trendy boots to complement every outfit.
          <br /> • <span>Kids' Footwear</span>: Durable and stylish shoes that keep up with
          your little ones' active lifestyles. <br />• <span>Sports Shoes</span>:
          High-performance footwear designed for athletes and fitness
          enthusiasts.
        </p>
      </div>
      <div className="choose">
        <h1> Why Choose Us?</h1>
        <br />
        <p>
          1.<span>Quality Assurance:</span> We source our products from the best
          manufacturers to ensure top-notch quality and durability.<br/> 2.<span>
          Affordable Prices</span>: Enjoy competitive pricing without compromising on
          style or comfort.<br/>3.<span>Customer Service</span>: Our friendly and knowledgeable
          staff are always ready to assist you in finding the perfect pair of
          shoes. <br/> 4.<span>Community Trust</span>: With a legacy of over 40 years, we have
          built lasting relationships with our customers, who trust us for their
          footwear needs.
        </p>
      </div>
      
      
    </>
  );
}

export default Home;
