import React, { Component } from "react";
import "./Brand.css";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import img1 from "./woodland-logo.jpg";
import img2 from "./crocs-logo.png";
import img3 from "./puma-logo.png";
import img4 from "./Skechers-logo.png";
import img5 from "./Bata-logo.png";
import img6 from "./sparx-logo.svg";
import img7 from "./adda-logo.jpg";
import img8 from "./liberty.png";
import img9 from "./LEE-FOX.jpg";
import img10 from "./leecooper-logo.png";

class Brand extends Component {
  render() {
    const settings = {
      dots: true,
      infinite: true,
      slidesToShow: 3,
      slidesToScroll: 1,
      autoplay: true,
      speed: 1000,
      autoplaySpeed: 1000,
      cssEase: "linear",
    };
    return (
      <div className="main-container">
        <h1>OUR BRAND'S</h1>
        <Slider {...settings}>
          <div className="container">
            <img src={img1} alt="img1" />
          </div>
          <div className="container">
            <img src={img2} alt="img2" />
          </div>
          <div className="container">
            <img src={img3} alt="img3" />
          </div>
          <div className="container">
            <img src={img4} alt="img4" />
          </div>
          <div className="container">
            <img src={img5} alt="img5" />
          </div>
          <div className="container">
            <img src={img6} alt="img6" />
          </div>
          <div className="container">
            <img src={img7} alt="img7" />
          </div>
          <div className="container">
            <img src={img8} alt="img8" />
          </div>
          <div className="container">
            <img src={img9} alt="img9" />
          </div>
          <div className="container">
            <img src={img10} alt="img10" />
          </div>
        </Slider>
      </div>
    );
  }
}

export default Brand;
