import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import logo from './Shoeworld_Logo.png';
import img1 from './menu_24dp_E8EAED_FILL0_wght400_GRAD0_opsz24.png'
import './Navbar.css';

<link rel="stylesheet" href="https://fonts.googleapis.com/css2?family=Material+Symbols+Outlined:opsz,wght,FILL,GRAD@20..48,100..700,0..1,-50..200" />

const Navbar = () => {
  const [menuOpen, setMenuOpen] = useState(false);
  const [activeMenu, setActiveMenu] = useState("home");

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };

  return (
    <nav className="navbar">
      <Link to="/" id="#home" className='mynav'>
      <div className="nav " >
      <img src={logo} alt="Logo" style={{marginLeft:'1.5rem'}}className="nav-logo" />
      <button className="navbar-toggle" style={{marginLeft:'5rem'}} onClick={toggleMenu}><img src={img1} style={{width:'30px',backgroundColor:'black'}} alt='img1'></img></button>
      </div>
        
      </Link>
      
      <ul className={`navbar-menu ${menuOpen ? 'show' : ''}`}>
        <li>
          <Link 
            to="/" 
            onClick={() => { setActiveMenu("Home"); setMenuOpen(false); }} 
            className={activeMenu === "Home" ? "active" : ""}
          >
            HOME
          </Link>
        </li>
        <li>
          <a href="#about" onClick={() => { setActiveMenu("About"); setMenuOpen(false); }} className={activeMenu === "About" ? "active" : ""}>
            ABOUT
          </a>
        </li>
        <li>
          <a href="#footer" onClick={() => { setActiveMenu("Contact"); setMenuOpen(false); }} className={activeMenu === "Contact" ? "active" : ""}>
            CONTACT
          </a>
        </li>
      </ul>
    </nav>
  );
};

export default Navbar;
