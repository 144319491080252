import './Slider.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import Carousel from 'react-bootstrap/Carousel';
import img1 from './Branch1.jpeg';
import img2 from './Branch-inside.jpeg';
import img3 from './Branch-2.jpeg';
import img4 from './Branch2-inside.jpeg';
import img5 from './Branch-3.jpeg';
import img6 from './Branch3-inside.jpeg';

function Slider() {
  return (
    <Carousel>
      <Carousel.Item interval={1000}>
        <div className='carousel-image ' style={{ backgroundImage: `url(${img1})`, backgroundSize:'cover' }}>
          <Carousel.Caption>
            
            <h3></h3>
            <p></p>
          </Carousel.Caption>
        </div>
      </Carousel.Item>
      <Carousel.Item interval={500}>
        <div className='carousel-image' style={{ backgroundImage: `url(${img2})`, backgroundSize:'cover' ,backgroundPosition:'center' }}>
          <Carousel.Caption>
           
          </Carousel.Caption>
        </div>
      </Carousel.Item>
      <Carousel.Item>
        <div className='carousel-image' style={{ backgroundImage: `url(${img3})` , backgroundSize:'cover',backgroundPosition:'center'}}>
          <Carousel.Caption>
            
          </Carousel.Caption>
        </div>
      </Carousel.Item>
      <Carousel.Item>
        <div className='carousel-image' style={{ backgroundImage: `url(${img4})` , backgroundSize:'cover',backgroundPosition:'center'}}>
          <Carousel.Caption>
     
          </Carousel.Caption>
        </div>
      </Carousel.Item>
      <Carousel.Item>
        <div className='carousel-image' style={{ backgroundImage: `url(${img5})` , backgroundSize:'cover', backgroundPosition:'center',backgroundRepeat:'no-repeat'}}>
          <Carousel.Caption>
          
          </Carousel.Caption>
        </div>
      </Carousel.Item>
      <Carousel.Item>
        <div className='carousel-image' style={{ backgroundImage: `url(${img6})`, backgroundSize:'cover', backgroundPosition:'center' }}>
          <Carousel.Caption>

          </Carousel.Caption>
        </div>
      </Carousel.Item>
    </Carousel>
  );
}

export default Slider;
