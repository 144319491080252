
import './App.css';

import Navbar from './components/Navbar/Navbar';

import { Route,Routes } from 'react-router-dom';
import Home from './pages/Home/Home';
import Footer from './components/Footer/Footer';


function App() {
  return (
    <>
    <div className="App">
      
      
      <Navbar/>
      
      <Routes className="routes">
      <Route path="/" element={<Home/>}/>
     
      </Routes>
      
    </div>
    <Footer/>
    </>
  );
}

export default App;
